export default {
  WebinarUnavailable: {
    Header: 'WebinarUnavailable.Header',
    Content: {
      Header: 'WebinarUnavailable.Content.Header',
      SubHeader: 'WebinarUnavailable.Content.SubHeader',
      Features: {
        Webinars: 'WebinarUnavailable.Content.Features.Webinars',
        Email: 'WebinarUnavailable.Content.Features.Email',
        LPS: 'WebinarUnavailable.Content.Features.LPS',
        Automation: 'WebinarUnavailable.Content.Features.Automation',
      },
    },
    SignUpButton: 'WebinarUnavailable.SignUpButton',
  },
};
