import { IChangeLanguage, IFetchTranslation, IFetchTranslationsSuccess } from '../actions/webinarUnavailable';
import { WebinarUnavailableActionType } from '../constants/actionTypes';
import { currentLanguage } from '../config';

export interface IWebinarUnavailableState {
  selectedLanguage: string;
  isFetchingTranslations: boolean;
}

const initialState: IWebinarUnavailableState = {
  selectedLanguage: currentLanguage.toUpperCase(),
  isFetchingTranslations: false,
};

type WebinarUnavailableAction = IChangeLanguage | IFetchTranslation | IFetchTranslationsSuccess;

export function webinarUnavailable(
  state: IWebinarUnavailableState = initialState,
  action?: WebinarUnavailableAction,
): IWebinarUnavailableState {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case WebinarUnavailableActionType.CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.language,
      };
    case WebinarUnavailableActionType.FETCH_TRANSLATIONS:
      return {
        ...state,
        isFetchingTranslations: true,
      };
    case WebinarUnavailableActionType.FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        isFetchingTranslations: false,
      };
    default:
      return state;
  }
}
