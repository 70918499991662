import * as React from 'react';
import { Button, Loader, Page } from '@gr/ui';
import { Translation } from '@gr/translations';

import {
  LogoWithIcons,
  LogoWithIconsWL,
  Logo,
  LogoWithIconsWLContainer,
  LanguageSelect,
  ContentContainer,
  FeaturesImage,
  FeaturesList,
  FeaturesListItem,
  FeaturesWrapper,
  PageContainer,
  SignUpFreeButtonWrapper,
  StyledHeading,
  StyledPageHeader,
  TryGetResponseFree,
  PageWrapper,
} from '../../../components';

import { START_FREE_TRIAL } from '../../../constants/urls';
import TRANSLATIONS from '../constants/translations';

import { branding, languages } from '../config';

export interface IWebinarUnavailableComponentProps {
  selectedLanguage: string;
  isFetchingTranslations: boolean;
  changeLanguage: (lang: string) => void;
  fetchTranslations: (lang: string) => void;
}

export class WebinarUnavailableComponent extends React.PureComponent<IWebinarUnavailableComponentProps> {
  public render(): React.ReactNode {
    const { selectedLanguage, changeLanguage, fetchTranslations, isFetchingTranslations } = this.props;
    const SIGN_UP_FREE_URL = `${START_FREE_TRIAL}?lang=${selectedLanguage.toLowerCase()}`;

    return (
      <PageWrapper key={selectedLanguage}>
        <Page type="narrow" offsetHorizontal="none" offsetVertical="none">
          <PageContainer className={branding.whiteLabel ? 'whitelabel-page' : null}>
            {isFetchingTranslations ? (
              <Loader type="center" size="large" />
            ) : (
              <React.Fragment>
                <Logo {...branding} />
                <StyledPageHeader>
                  <Translation id={TRANSLATIONS.WebinarUnavailable.Header} />
                </StyledPageHeader>
                {branding.whiteLabel ? (
                  <LogoWithIconsWLContainer>
                    <LogoWithIconsWL />
                  </LogoWithIconsWLContainer>
                ) : (
                  <React.Fragment>
                    <ContentContainer>
                      <StyledHeading>
                        <Translation id={TRANSLATIONS.WebinarUnavailable.Content.Header} />
                      </StyledHeading>
                      <TryGetResponseFree>
                        <Translation id={TRANSLATIONS.WebinarUnavailable.Content.SubHeader} />
                      </TryGetResponseFree>
                      <FeaturesWrapper>
                        <FeaturesList>
                          <FeaturesListItem>
                            <Translation id={TRANSLATIONS.WebinarUnavailable.Content.Features.Webinars} />
                          </FeaturesListItem>
                          <FeaturesListItem>
                            <Translation id={TRANSLATIONS.WebinarUnavailable.Content.Features.Email} />
                          </FeaturesListItem>
                          <FeaturesListItem>
                            <Translation id={TRANSLATIONS.WebinarUnavailable.Content.Features.LPS} />
                          </FeaturesListItem>
                          <FeaturesListItem>
                            <Translation id={TRANSLATIONS.WebinarUnavailable.Content.Features.Automation} />
                          </FeaturesListItem>
                        </FeaturesList>
                        <FeaturesImage>
                          <LogoWithIcons />
                        </FeaturesImage>
                      </FeaturesWrapper>
                    </ContentContainer>
                    <SignUpFreeButtonWrapper>
                      <Button size="medium" href={SIGN_UP_FREE_URL} data-ats-webinar-unavailable="sign-up-free-button">
                        <Translation id={TRANSLATIONS.WebinarUnavailable.SignUpButton} />
                      </Button>
                    </SignUpFreeButtonWrapper>
                  </React.Fragment>
                )}
                <LanguageSelect
                  dataAtsKey="webinar-unavailable"
                  selectedLanguage={selectedLanguage}
                  changeLanguage={changeLanguage}
                  fetchTranslations={fetchTranslations}
                  languages={languages}
                />
              </React.Fragment>
            )}
          </PageContainer>
        </Page>
      </PageWrapper>
    );
  }
}
