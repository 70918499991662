import * as React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from '@gr/ui';
import { Provider } from 'react-redux';
import { TranslationProvider } from '@gr/translations';
import { currentLanguage } from './config';
import WebinarUnavailable from './containers/WebinarUnavailable';
import { bootstrap } from '../../utils/bootstrap';
import { store } from './store';

bootstrap(currentLanguage, ['WebinarUnavailable'], () => {
  render(
    <TranslationProvider>
      <Provider store={store}>
        <ThemeProvider>
          <WebinarUnavailable />
        </ThemeProvider>
      </Provider>
    </TranslationProvider>,
    document.getElementById('root'),
  );
});
