import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { initTranslations } from '../../../utils/translations';
import { WebinarUnavailableActionType } from '../constants/actionTypes';
import { IWebinarUnavailableState } from '../reducers/webinarUnavailable';

export interface IChangeLanguage extends Action<WebinarUnavailableActionType.CHANGE_LANGUAGE> {
  language: string;
}

export function changeLanguage(language: string): IChangeLanguage {
  return {
    type: WebinarUnavailableActionType.CHANGE_LANGUAGE,
    language,
  };
}

export type IFetchTranslation = Action<WebinarUnavailableActionType.FETCH_TRANSLATIONS>;

type IFetchTranslations = ThunkAction<
  void,
  IWebinarUnavailableState,
  undefined,
  IFetchTranslation | IFetchTranslationsSuccess
>;
export function fetchTranslations(lang: string): IFetchTranslations {
  return (dispatch: ThunkDispatch<IWebinarUnavailableState, undefined, AnyAction>) => {
    dispatch({
      type: WebinarUnavailableActionType.FETCH_TRANSLATIONS,
    });
    return initTranslations(lang, ['WebinarUnavailable'])
      .then(() => dispatch(fetchTranslationsSuccess()))
      .catch(() => {
        // do nothing
      });
  };
}

export type IFetchTranslationsSuccess = Action<WebinarUnavailableActionType.FETCH_TRANSLATIONS_SUCCESS>;

export function fetchTranslationsSuccess(): IFetchTranslationsSuccess {
  return {
    type: WebinarUnavailableActionType.FETCH_TRANSLATIONS_SUCCESS,
  };
}
