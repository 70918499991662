import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { WebinarUnavailableComponent } from './WebinarUnavailableComponent';
import { changeLanguage, fetchTranslations } from '../actions/webinarUnavailable';
import { getSelectedLanguage, isFetchingTranslations } from '../selectors/webinarUnavailable';
import { IWebinarUnavailableState } from '../reducers/webinarUnavailable';
import { ThunkDispatch } from 'redux-thunk';

export const mapStateToProps = (state: IWebinarUnavailableState) => ({
  selectedLanguage: getSelectedLanguage(state),
  isFetchingTranslations: isFetchingTranslations(state),
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<IWebinarUnavailableState, undefined, AnyAction>) => ({
  changeLanguage: (language) => dispatch(changeLanguage(language)),
  fetchTranslations: (lang) => dispatch(fetchTranslations(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebinarUnavailableComponent);
