import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from '../reducers/rootReducer';

const initialState = {};

const loggerMiddleware = createLogger({
  collapsed: true,
});

const PRODUCTION: boolean = process.env.NODE_ENV === 'production';
const middlewares = [thunkMiddleware];
if (!PRODUCTION) {
  middlewares.push(loggerMiddleware);
}

const enhancer = compose(
  applyMiddleware(...middlewares),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && !PRODUCTION
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f,
);

export const store = createStore(rootReducer(), initialState, enhancer);
